import { ElementType, forwardRef } from "react";
import type { PC, PolymorphicPropsWithRef, PolymorphicRef } from "@finbricks/tools";
import clsx from "clsx";
import { Icon, IconType } from "../../Icon/Icon";

type LinkOwnProps = {
  icon?: IconType;
  variant?: "normal" | "disabled";
};

const linkVariantsClassesMapping: Record<Required<LinkOwnProps>["variant"], string> = {
  normal: "text-brand-50 hover:text-green-25 active:text-green-75",
  disabled: "text-light-75",
};

const defaultAs = "a";
export const Link: PC<LinkOwnProps, typeof defaultAs> = forwardRef(
  <T extends ElementType = typeof defaultAs>(
    { as, icon, children, className, variant = "normal", ...rest }: PolymorphicPropsWithRef<T, LinkOwnProps>,
    ref: PolymorphicRef<T>
  ) => {
    const As = as || defaultAs;
    return (
      <As
        {...rest}
        ref={ref}
        className={clsx(
          "typo-sm--link--bold inline-flex items-center justify-end gap-x-1 self-end text-right",
          linkVariantsClassesMapping[variant],
          className
        )}
      >
        {children}
        {icon ? <Icon className={clsx("h-6 w-6 shrink-0")} type={icon} aria-hidden /> : null}
      </As>
    );
  }
);

Link.displayName = "FormField.Link";
