import { ChangeEventHandler, FormEvent, useState } from "react";
import { Button, Select, Tag } from "@finbricks/ui";
import { Card } from "@finbricks/ui/src/Card/Card";
import { FormField } from "@finbricks/ui/src/inputs/FormField/FormField";
import { TextField } from "@finbricks/ui/src/inputs/TextField";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { z } from "zod";
import { variableSymbolMap } from "../../shared";
import { url } from "../../urls";
import { notify } from "../../utils/notification";
import { useTransaction } from "./hooks/useTransaction";

const prefillAmounts = [10, 50, 100, 500];

type PaymentForm = {
  amount: number;
  projectVariableSymbol: string;
};

const defaultFormValues: PaymentForm = {
  amount: 5,
  projectVariableSymbol: variableSymbolMap[0].id,
};
const defaultErrors: Record<keyof PaymentForm, string> = {
  amount: "",
  projectVariableSymbol: "",
};

export const PaymentGatewayCard = () => {
  const { t, lang } = useTranslation();
  const { initTransaction, loading } = useTransaction(() =>
    notify.negative({
      headline: t("alertOneTitle"),
      message: t("alertOneDescription"),
    })
  );

  const [formData, setFormData] = useState(defaultFormValues);
  const [errors, setErrors] = useState(defaultErrors);

  const validate = (formData: PaymentForm) => {
    const result = z
      .object({
        amount: z
          .number({
            coerce: true,
            required_error: t("validation.amount.required"),
            invalid_type_error: t("validation.amount.invalidType"),
          })
          .positive({
            message: t("validation.amount.positive"),
          })
          .max(500, t("validation.amount.max")),
        projectVariableSymbol: z.string({
          required_error: t("validation.required"),
        }),
      })
      .safeParse(formData);

    if (!result.success) {
      const formatted = result.error.format();
      setErrors({
        amount: formatted.amount?._errors[0] ?? "",
        projectVariableSymbol: formatted.projectVariableSymbol?._errors[0] ?? "",
      });
      (document.getElementById("amount") as HTMLInputElement).select();
      return false;
    }

    return true;
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading || !validate(formData)) {
      return;
    }

    initTransaction(formData.amount, formData.projectVariableSymbol, lang);
  };

  const clearErrors = () => {
    setErrors(defaultErrors);
  };

  const changeAmount = (amount: number) => {
    clearErrors();
    setFormData((prevState) => ({
      ...prevState,
      amount,
    }));
  };

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
    if (errors !== defaultErrors) {
      clearErrors();
    }
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Card>
      <p className={"typo-sm text-dark-25 text-center"}>
        {t("moneyDonation")}
        {"\n"}
      </p>

      <div className={"flex justify-center"}>
        <Link
          className={"typo-sm--link--bold text-brand-75 text-center"}
          href={url.jistotaFoundation}
          target={"_blank"}
        >
          {t("foundation")}
        </Link>
      </div>

      <form noValidate onSubmit={handleFormSubmit} className={"mt-6 flex flex-col gap-6"}>
        <FormField
          messages={errors.amount ? [{ key: "error", variant: "alert", icon: "warning", children: errors.amount }] : []}
        >
          <div className={"flex items-end gap-2"}>
            <TextField
              id={"amount"}
              name={"amount"}
              value={formData.amount}
              onChange={onChange}
              label={t("amount")}
              type={"number"}
              state={errors.amount ? "negative" : "base"}
              min={1}
              max={500}
              size={"large"}
              className={"w-full"}
            />
            <span className={"typo-md--bold text-dark-25 mb-4"}>{t("currency")}</span>
          </div>
          <div className={"flex flex-wrap items-center justify-between justify-around gap-2 py-2 lg:justify-between"}>
            {prefillAmounts.map((amount) => (
              <Tag key={amount} onClick={() => changeAmount(amount)} color={"green"} className={"flex-1"}>
                {amount} {t("currency")}
              </Tag>
            ))}
          </div>
        </FormField>

        <FormField
          messages={
            errors.projectVariableSymbol
              ? [{ key: "error", variant: "alert", icon: "warning", children: errors.projectVariableSymbol }]
              : []
          }
          link={
            <FormField.Link as={Link} href={url.jistotaFoundationProjects} target={"_blank"}>
              {t("projectsAll")}
            </FormField.Link>
          }
        >
          <Select
            id={"projectVariableSymbol"}
            name={"projectVariableSymbol"}
            label={t("project")}
            onChange={onChange}
            state={errors.projectVariableSymbol ? "negative" : "base"}
            className={"w-full"}
            size={"large"}
          >
            {variableSymbolMap.map((vs) => (
              <option key={vs.id} value={vs.id}>
                {vs.name}
              </option>
            ))}
          </Select>
        </FormField>

        <Button type={"submit"} size={"large"} className={"w-full"} loading={loading}>
          {t("paymentButton")}
        </Button>
      </form>
    </Card>
  );
};
