
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { InfoAPICard, InfoCard } from "../components";
import { CustomizationCard } from "../components/cards/customization-card/CustomizationCard";
import { PageLayout } from "../layouts/page-layout/PageLayout";
import { Contact, PaymentGateway } from "../modules";
export default function HomePage() {
    return (<PageLayout>
      <div className={"lg:px-8"}>
        <PaymentGateway />
      </div>
      <div className={"c-app-row c-app-row--md mt-24"}>
        <div className={"flex flex-col gap-8 md:grid md:grid-cols-12"}>
          <InfoCard className={"md:col-span-5 md:grid"}/>
          <InfoAPICard className={"md:col-span-7 md:grid"}/>
        </div>
      </div>
      <div className={"c-app-row c-app-row--md mt-8"}>
        <CustomizationCard />
      </div>
      <div className={"lg:c-app-row lg:c-app-row--lg mt-24"}>
        <Contact />
      </div>
    </PageLayout>);
}

    async function __Next_Translate__getStaticProps__1912bee1f39__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1912bee1f39__ as getStaticProps }
  