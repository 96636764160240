import { ElementType, forwardRef, ReactNode } from "react";
import type { PC, PolymorphicPropsWithRef, PolymorphicRef } from "@finbricks/tools";
import clsx from "clsx";

type TextFieldProps = {
  label?: ReactNode;
  id: string;
  state?: "base" | "negative";
  size?: "large" | "medium";
};

const stateToClassNameMap: Record<Required<TextFieldProps>["state"], string> = {
  base: "ui-text-field--base",
  negative: "ui-text-field--negative",
};
const sizeToClassNameMap: Record<Required<TextFieldProps>["size"], string> = {
  large: "ui-text-field--large",
  medium: "ui-text-field--medium",
};

const defaultAs = "input";

/**
 * Polymorphic decorator with the styles for label and elements as text input,
 * text area and select
 */
export const TextField: PC<TextFieldProps, typeof defaultAs> = forwardRef(
  <T extends ElementType = typeof defaultAs>(
    {
      as,
      state = "base",
      size = "medium",
      label,
      id,
      className,
      disabled,
      readOnly,
      ...rest
    }: PolymorphicPropsWithRef<T, TextFieldProps>,
    ref: PolymorphicRef<T>
  ) => {
    const As = as || defaultAs;
    const isTouchable: boolean = !disabled && !readOnly;

    return (
      <div
        className={clsx(
          "ui-text-field",
          sizeToClassNameMap[size],
          isTouchable ? stateToClassNameMap[state] : "ui-text-field--disabled",
          className
        )}
      >
        {label ? (
          <label className={clsx("ui-text-field__label")} htmlFor={id}>
            {label}
          </label>
        ) : null}

        <As {...rest} ref={ref} id={id} disabled={disabled} readOnly={readOnly} className={"ui-text-field__element"} />
      </div>
    );
  }
);

TextField.displayName = "TextField";
