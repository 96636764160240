import { Card } from "@finbricks/ui/src/Card/Card";
import clsx from "clsx";
import Image from "next/image";

import useTranslation from "next-translate/useTranslation";
import illustration1 from "../../../public/assets/illustration_1.webp";

type InfoCardProps = {
  className?: string;
};

export const InfoCard = ({ className = "" }: InfoCardProps) => {
  const { t } = useTranslation();
  return (
    <Card className={clsx("flex flex-col overflow-hidden", className)}>
      <h2 className={"text-dark-75 typo-h2"}>{t("infoBoxOneTitle")}</h2>
      <p className={"typo-md text-dark-25 mb-7 mt-4 "}>{t("infoBoxOneDescription")}</p>
      <div className={"flex flex-1 items-end"}>
        <div className={"relative aspect-[4/3] w-full max-w-md"}>
          <Image
            className={"absolute h-full w-auto object-contain object-left"}
            style={{ maxWidth: "unset" }}
            src={illustration1}
            alt={"infocard illustration"}
          />
        </div>
      </div>
    </Card>
  );
};
