export const variableSymbolMap: {
  id: string;
  name: string;
  variableSymbol: string;
}[] = [
  {
    id: "1",
    name: "Rodina a děti",
    variableSymbol: "1111",
  },
  {
    id: "2",
    name: "Hospicová a paliativní péče",
    variableSymbol: "2222",
  },
  {
    id: "3",
    name: "Udržitelná budoucnost",
    variableSymbol: "3333",
  },
];
