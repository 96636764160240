import clsx from "clsx";

type CardProps = JSX.IntrinsicElements["div"] & {
  variant?: "first" | "second" | "third";
  omitPadding?: boolean;
};

const variantToClassMapper: Record<NonNullable<CardProps["variant"]>, string> = {
  first: "",
  second: "ui-card--second",
  third: "ui-card--third",
};

export const Card = ({ children, className, variant = "first", omitPadding, ...rest }: CardProps) => {
  const variantClass = variantToClassMapper[variant];
  return (
    <div {...rest} className={clsx("ui-card", variantClass, !omitPadding && "ui-card__content", className)}>
      {children}
    </div>
  );
};
