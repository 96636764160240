import { useCallback, useState } from "react";
import { useRouter } from "next/router";

export const useTransaction = (onError?: () => void) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const initTransaction = useCallback(
    async (amount: number, projectVariableSymbolId: string, lang: string) => {
      setLoading(true);
      const req = await fetch("/api/transaction-init", {
        body: JSON.stringify({ amount, projectVariableSymbolId, lang }),
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });

      const res = await req.json();

      if (!res.redirectUrl) {
        console.error(res);
        setLoading(false);
        onError?.();
        return;
      }

      await router.push(res.redirectUrl);
      setLoading(false);
    },
    [onError, router]
  );

  return {
    initTransaction,
    loading,
  };
};
