import { Button } from "@finbricks/ui";
import { Card } from "@finbricks/ui/src/Card/Card";
import clsx from "clsx";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { url } from "../../../urls";
import { InteractivePaletteViewer } from "./InteractivePaletteViewer";

type CustomizationCardProps = {
  className?: string;
};

export const CustomizationCard = ({ className }: CustomizationCardProps) => {
  const { t, lang } = useTranslation();
  const linkUrl = lang === "cs" ? url.customizationCs : url.customizationEn;
  return (
    <Card>
      <div className={clsx("flex flex-col gap-6 lg:flex-row lg:gap-[4.5rem]", className)}>
        <div className={"relative flex-1"}>
          <div
            className={
              "h-full w-full lg:absolute lg:right-0 lg:top-0 lg:bottom-0 lg:w-[calc(100%+theme('spacing.app--outer'))]"
            }
          >
            <InteractivePaletteViewer />
          </div>
        </div>
        <div className={"flex-1"}>
          <h2 className={"text-dark-75 typo-h2"}>{t("exampleBoxOneTitle")}</h2>
          <p className={"typo-md text-dark-25 mt-4"}>{t("exampleBoxOneDescription")}</p>
          <Button
            as={Link}
            href={linkUrl}
            target={"_blank"}
            className={"my-8"}
            variant={"text-base"}
            icon={"arrow_right"}
            iconAlign={"right"}
          >
            {t("exampleBoxOneLink")}
          </Button>
        </div>
      </div>
    </Card>
  );
};
