import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";

type ContactInfoProps = JSX.IntrinsicElements["div"] & {
  titleClass?: string;
  subtitleClass?: string;
};
export const ContactInfo = ({ titleClass, subtitleClass, ...rest }: ContactInfoProps) => {
  const { t } = useTranslation();
  return (
    <div {...rest}>
      <h1 className={clsx("typo-h1 text-light-0 mb-4", titleClass)}>{t("contactBoxTitle")}</h1>
      <p className={clsx("typo-md", subtitleClass)}>{t("contactBoxDescription")}</p>
    </div>
  );
};
