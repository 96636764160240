import { ComponentProps, useState } from "react";
import { Tag } from "@finbricks/ui";
import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";

const colorsMapper: IllustrationProps[] = [
  {
    bgColor: "linear-gradient(66.57deg, #9F98EE 6.84%, #C0BBF4 35.56%, #DEDBFF 64.28%, #EAE8FB 93%)",
    ctaBgColor: "linear-gradient(180deg, #EAE8FB 0%, #D8D3FF 100%)",
    ctaBgHoverColor: "linear-gradient(180deg, #403BAA 0%, #332E98 100%)",
    ctaForegroundColor: "#6EE3A6",
    ctaRadiusClass: "lg:rounded-tl-[50px] rounded-tl-[25px]",
  },
  {
    bgColor: "linear-gradient(85.99deg, #D9E6FF 6.55%, #6295E3 93.45%)",
    ctaBgColor: "linear-gradient(180deg, #D9E6FF 0%, #CEDFFF 100%)",
    ctaBgHoverColor: "linear-gradient(180deg, #3152C4 0%, #2443AE 100%)",
    ctaForegroundColor: "#142B64",
    ctaRadiusClass: "rounded-0",
  },
  {
    bgColor: "linear-gradient(254.84deg, #EEC459 10.72%, #8F6701 89.4%)",
    ctaBgColor: "#DC9E00",
    ctaBgHoverColor: "#422F00",
    ctaForegroundColor: "#FFE9B0",
    ctaRadiusClass: "rounded-tl-[8px]",
  },
];

const tagColorsMapper: ComponentProps<typeof Tag>["color"][] = ["green", "blue", "yellow"];

export const InteractivePaletteViewer = () => {
  const [schemeIndex, setSchemeIndex] = useState(0);
  const { t } = useTranslation();
  return (
    <div className={"flex h-full w-full flex-col gap-4 lg:gap-8"}>
      <div className={"relative flex items-center justify-end gap-6"}>
        <div className={"bg-light-50 absolute left-0 h-px w-full"} />
        {colorsMapper.map((_, i) => (
          <Tag
            className={clsx("z-[1]", i > 1 && "hidden md:block")}
            key={i}
            onClick={() => setSchemeIndex(i)}
            onMouseOver={() => setSchemeIndex(i)}
            color={i === schemeIndex ? tagColorsMapper[i] : "gray"}
            variant={i === schemeIndex ? "filled" : "stroked"}
          >
            {t("example")} {i + 1}
          </Tag>
        ))}
      </div>
      <div className={"relative flex h-full w-full items-center justify-end"}>
        <Illustration {...colorsMapper[schemeIndex]} />
      </div>
    </div>
  );
};

type IllustrationProps = {
  bgColor: string;
  ctaBgColor: string;
  ctaBgHoverColor: string;
  ctaForegroundColor: string;
  ctaRadiusClass: string;
};

const Illustration = ({
  bgColor,
  ctaBgColor,
  ctaBgHoverColor,
  ctaForegroundColor,
  ctaRadiusClass,
}: IllustrationProps) => {
  const colors = [
    { name: "cta.primary.background", value: ctaBgColor },
    { name: "cta.primary.backgroundHover", value: ctaBgHoverColor },
    { name: "cta.secondary.foreground", value: ctaForegroundColor },
  ];

  return (
    <div className={"w-full justify-center sm:flex lg:block"}>
      <div className={"relative w-full md:w-[350px] lg:w-full"}>
        <div className={"absolute right-0 top-0 z-[10] flex flex-col gap-2 lg:gap-3"}>
          {colors.map((color) => (
            <div key={color.name} className={"flex items-center gap-2"}>
              <div
                className={"transition-color h-4 w-4 rounded-[2px] duration-150 lg:h-6 lg:w-6"}
                style={{ background: color.value }}
              />
              <span
                className={"text-light-100 rounded-[4px] bg-[#F1F1F1] p-1.5 text-[8px] font-semibold lg:text-[12px]"}
              >
                {color.name}
              </span>
            </div>
          ))}
        </div>
        <div className={"pt-4 pl-2"}>
          <div
            className={
              "transition-color relative h-[110px] w-full shadow-md duration-300 md:h-[150px] lg:h-[177px] lg:w-[415px]"
            }
            style={{ background: bgColor }}
          >
            <div
              className={clsx(
                "relative -top-[4px] -left-[4px] h-[70px] w-[70px] border-t-2 border-l-2 transition-all duration-300 lg:-left-[8px] lg:-top-[8px] lg:h-[100px] lg:w-[100px]",
                ctaRadiusClass
              )}
              style={{ borderColor: ctaForegroundColor }}
            />
            <div
              className={"transition-color absolute left-3 top-3 h-2 w-2 rounded-full duration-300 lg:left-6 lg:top-6"}
              style={{ backgroundColor: ctaForegroundColor }}
            />
            <div
              className={clsx(
                "absolute bottom-[4px] right-[4px] h-[70px] w-[70px] rotate-180 border-t-2 border-l-2 transition-all duration-300 lg:bottom-[8px] lg:right-[8px] lg:h-[100px] lg:w-[100px]",
                ctaRadiusClass
              )}
              style={{ borderColor: ctaForegroundColor }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
