import { Button } from "@finbricks/ui";
import { Card } from "@finbricks/ui/src/Card/Card";
import Image from "next/image";
import Link from "next/link";

import useTranslation from "next-translate/useTranslation";
import illustration2 from "../../../public/assets/illustration_2.webp";
import { url } from "../../urls";

type InfoAPICardProps = {
  className?: string;
};

export const InfoAPICard = ({ className }: InfoAPICardProps) => {
  const { t } = useTranslation();
  return (
    <Card className={className}>
      <h2 className={"text-dark-75 typo-h2"}>{t("infoBoxTwoTitle")}</h2>
      <p className={"typo-md text-dark-25 mt-4"}>{t("infoBoxTwoDescription")}</p>
      <div>
        <Button
          as={Link}
          href={url.whitebricksApi}
          target={"_blank"}
          className={"my-8 text-left"}
          variant={"text-base"}
          icon={"arrow_right"}
          iconAlign={"right"}
        >
          {t("infoBoxTwoLink")}
        </Button>
      </div>
      <Image className={"mt-4"} src={illustration2} alt={"API Info card illustration"} />
    </Card>
  );
};
