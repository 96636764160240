import clsx from "clsx";

type TagProps = JSX.IntrinsicElements["button"] & {
  color: "green" | "blue" | "yellow" | "red" | "gray" | "black";
  size?: "small";
  variant?: "filled" | "stroked";
};

const sizeToClassMap: Record<Required<TagProps>["size"], string> = {
  small: "text-sm leading-4.5 py-1.5 px-3",
};

const colorToClassMap: Record<Required<TagProps>["color"], Record<Required<TagProps>["variant"], string>> = {
  green: {
    filled: "bg-brand-0 text-brand-50",
    stroked: "ring-brand-0 text-brand-50",
  },
  blue: {
    filled: "bg-blue-0 text-blue-50",
    stroked: "ring-blue-0 text-blue-50",
  },
  yellow: {
    filled: "bg-gold-0 text-gold-75",
    stroked: "ring-gold-0 text-gold-75",
  },
  red: {
    filled: "bg-red-0 text-red-50",
    stroked: "ring-red-0 text-red-50",
  },
  gray: {
    filled: "bg-light-50 text-dark-25",
    stroked: "ring-light-50 text-dark-25 bg-light-0",
  },
  black: {
    filled: "bg-dark-25 text-light-0",
    stroked: "ring-dark-25 text-dark-25",
  },
};

export const Tag = ({ color, size = "small", variant = "filled", className, children, ...rest }: TagProps) => {
  return (
    <button
      {...rest}
      type={"button"}
      className={clsx(
        "whitespace-nowrap rounded-full text-center font-bold",
        sizeToClassMap[size],
        colorToClassMap[color][variant],
        variant === "stroked" && "ring-2 ring-inset",
        className
      )}
    >
      {children}
    </button>
  );
};
