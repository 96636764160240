import { useCallback, useState } from "react";

export const useContact = () => {
  const [loading, setLoading] = useState(false);

  const requestContact = useCallback(async (payload: { name: string; email: string; query?: string }, lang: string) => {
    setLoading(true);
    const req = await fetch("/api/contact", {
      body: JSON.stringify({ ...payload, lang }),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    setLoading(false);
    return req.status;
  }, []);

  return {
    requestContact,
    loading,
  };
};
