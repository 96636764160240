import { ChangeEventHandler, FormEvent, useState } from "react";
import { Button, Input } from "@finbricks/ui";
import { FormField } from "@finbricks/ui/src/inputs/FormField/FormField";
import { TextField } from "@finbricks/ui/src/inputs/TextField";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { z } from "zod";
import { url } from "../../../urls";
import { notify } from "../../../utils/notification";
import { useContact } from "../hooks/useContact";

type ContactFormType = {
  name: string;
  email: string;
  query?: string;
  subject?: string; //honeypot input
};

const defaultFormValues: ContactFormType = {
  name: "",
  email: "",
  query: "",
  subject: "",
};
const defaultErrors: Record<keyof ContactFormType, string> = {
  name: "",
  email: "",
  query: "",
  subject: "",
};

type ContactFormProps = JSX.IntrinsicElements["form"] & {
  size?: "large" | "medium";
};

export const ContactForm = ({ size = "medium", ...props }: ContactFormProps) => {
  const { requestContact, loading } = useContact();
  const [formData, setFormData] = useState(defaultFormValues);
  const [errors, setErrors] = useState(defaultErrors);
  const { t, lang } = useTranslation();
  const validate = (formData: ContactFormType) => {
    const result = z
      .object({
        name: z.string().min(1, t("validation.required")),
        email: z
          .string()
          .min(1, t("validation.required"))
          .email({
            message: t("validation.email"),
          }),
        query: z.string(),
      })
      .safeParse(formData);

    // antispam check
    if (formData.subject) {
      // hidden subject field is filled - this is spam. Block submit from proceeding
      return false;
    }

    if (!result.success) {
      const formatted = result.error.format();
      setErrors({
        name: formatted.name?._errors[0] ?? "",
        email: formatted.email?._errors[0] ?? "",
        query: formatted.query?._errors[0] ?? "",
        subject: "",
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading || !validate(formData)) {
      return;
    }
    const status = await requestContact(formData, lang);

    if (status.toString().startsWith("2")) {
      // API returns 202 on success
      notify.positive({
        headline: t("alertTwoTitle"),
        message: t("alertTwoDescription"),
      });
      //reset form on success
      setFormData(defaultFormValues);
      return;
    }
    notify.negative({
      headline: t("alertThreeTitle"),
      message: t("alertThreeDescription"),
    });
  };

  const clearErrors = () => {
    setErrors(defaultErrors);
  };

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    if (errors !== defaultErrors) {
      clearErrors();
    }
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <form {...props} noValidate onSubmit={handleSubmit}>
      <FormField
        messages={errors.name ? [{ key: "error", variant: "alert", icon: "warning", children: errors.name }] : []}
      >
        <Input
          size={size}
          label={t("contactBoxNameLabel")}
          placeholder={t("contactBoxNameValue")}
          id={"name"}
          name={"name"}
          onChange={onChange}
          value={formData.name}
          state={errors.name ? "negative" : "base"}
        />
      </FormField>
      <FormField
        messages={errors.email ? [{ key: "error", variant: "alert", icon: "warning", children: errors.email }] : []}
      >
        <Input
          size={size}
          label={t("contactBoxEmailLabel")}
          placeholder={t("contactBoxEmailValue")}
          id={"email"}
          name={"email"}
          onChange={onChange}
          value={formData.email}
          state={errors.email ? "negative" : "base"}
        />
      </FormField>
      <input
        id={"subject"}
        name={"subject"}
        type={"text"}
        required={true}
        placeholder={t("contactBoxNameValue")}
        autoComplete={"off"}
        className={"-z-dropdown absolute h-0 w-0 opacity-0"}
        onChange={onChange}
        value={formData.subject}
      />
      <FormField
        messages={errors.query ? [{ key: "error", variant: "alert", icon: "warning", children: errors.query }] : []}
      >
        <TextField
          size={size}
          as={"textarea"}
          label={t("contactBoxMessageLabel")}
          placeholder={t("contactBoxMessageValue")}
          id={"query"}
          name={"query"}
          onChange={onChange}
          value={formData.query}
          state={errors.query ? "negative" : "base"}
        />
      </FormField>
      <div className={"flex"}>
        <Link href={url.privacyPolicy} target={"_blank"} className={"typo-sm--link--bold text-brand-50 text-center"}>
          {t("contactBoxLink")}
        </Link>
      </div>
      <div className={"flex"}>
        <Button size={"large"} type={"submit"} loading={loading} className={"w-full"}>
          {t("contactBoxButton")}
        </Button>
      </div>
    </form>
  );
};
