import { ComponentProps } from "react";
import clsx from "clsx";
import { Link } from "./Link";
import { Message } from "./Message";

type FormFieldProps = JSX.IntrinsicElements["div"] & {
  /* primary you want use FormField.Link component */
  link?: JSX.Element;
  messages?: ({ key: string } & ComponentProps<typeof Message>)[];
};

export const FormField = ({ link, messages, children, ...rest }: FormFieldProps): JSX.Element => {
  const hasFooterContent: boolean = !!(link || messages?.length);

  return (
    <div {...rest}>
      {children}

      {hasFooterContent ? (
        <div className={clsx("flex flex-col gap-1", children && "mt-1")}>
          {messages?.map(({ variant, children, key, ...messageRest }) => (
            <Message key={key} variant={variant} {...messageRest}>
              {children}
            </Message>
          ))}

          {link}
        </div>
      ) : null}
    </div>
  );
};

FormField.Link = Link;
