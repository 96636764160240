import { Card } from "@finbricks/ui/src/Card/Card";
import { ContactForm } from "./components/ContactForm";
import { ContactInfo } from "./components/ContactInfo";

export const Contact = () => {
  return (
    <div className={"mb-[23rem] lg:mb-64"}>
      <div
        className={
          "lg:rounded-2 bg-gradient lg:px-app--inner flex flex-col gap-6 px-4 pt-16 pb-48 lg:flex-row lg:gap-8 lg:pb-16"
        }
      >
        <ContactInfo titleClass={"text-light-25"} subtitleClass={"text-light-0"} className={"flex-1 lg:py-16"} />
        <div className={"relative flex-1"}>
          <div className={"absolute inset-0 lg:-mr-8"}>
            <Card variant={"second"}>
              <ContactForm size={"large"} className={"flex w-full flex-col gap-4"} />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
