import Link from "next/link";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { url } from "../../urls";
import { PaymentGatewayCard } from "./PaymentGatewayCard";

export const PaymentGateway = () => {
  const { t } = useTranslation();
  return (
    <div
      className={"bg-gradient lg:rounded-2 lg:pt-19 lg:pb-25 c-app-row flex w-full flex-col items-center py-16 px-4"}
    >
      <h5 className={"typo-h5 text-light-0 text-center tracking-[2px]"}>Whitebricks</h5>
      <h1 className={"typo-h1 text-light-0 pt-1 text-center"}>{t("thePaymentMethodOfTheFuture")}</h1>
      <p className={"typo-md text-light-0 pt-2 text-center"}>
        <Trans
          i18nKey={"tryPaymentSolution"}
          components={{ link: <Link href={url.finbricks} className={"underline"} target={"_blank"} /> }}
        />
      </p>
      <div className={"w-full pt-8 sm:w-[28.75rem]"}>
        <PaymentGatewayCard />
      </div>
    </div>
  );
};
