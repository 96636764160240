import { ComponentProps, forwardRef, HTMLInputTypeAttribute } from "react";
import { TextField } from "../TextField";

type InputProps = Omit<ComponentProps<typeof TextField<"input">>, "children" | "as" | "type"> & {
  /** Limit default input type attribute to the types supported by Input component */
  type?: Extract<HTMLInputTypeAttribute, "text" | "email" | "number" | "password" | "tel" | "url">;
};
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return <TextField {...props} as={"input"} ref={ref} />;
});

Input.displayName = "Input";
