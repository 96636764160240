import clsx from "clsx";
import { Icon, IconType } from "../../Icon/Icon";

type MessageProps = JSX.IntrinsicElements["div"] & {
  icon?: IconType;
  variant: "neutral" | "info" | "warning" | "alert" | "disabled";
};

const variantsClassesMapping: Record<MessageProps["variant"], string> = {
  neutral: "text-dark-0",
  info: "text-blue-50",
  warning: "text-gold-50",
  alert: "text-red-50",
  disabled: "text-light-75",
};

export const Message = ({ icon, variant, className, children, ...rest }: MessageProps): JSX.Element => (
  <div className={clsx("typo-sm flex items-center gap-x-1", variantsClassesMapping[variant], className)} {...rest}>
    {icon ? <Icon className={clsx("h-4.5 w-4.5 shrink-0")} type={icon} aria-hidden /> : null}
    {children}
  </div>
);

Message.displayName = "FormField.Message";
